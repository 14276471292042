/**
 * @file
 * Custom JS behaviour for Hotel Page.
 */

(function ($) {

  Drupal.behaviors.hotel = {
    attach: function (context, settings) {

      if ($('body.node-type-hotel').length) {
        // Qui inserire il codice per la pagina tour.

        var controller = new ScrollMagic.Controller({
          globalSceneOptions: {
            triggerHook: "onLeave"
          }
        });

        $sistemazioni            = $('.view-servizi-struttura.view-display-id-block');
        $servizi_struttura       = $sistemazioni.find('.field-collection-item-field-servizi-struttura');
        $servizi_struttura_items = $servizi_struttura.find('.sistemazione-item');

        $servizi_struttura_items.find('.sistemazione-title-container').click(function (e) {
          e.preventDefault();
          $sistemazione_parent = $(this).closest('.sistemazione-item');
          $sistemazione_parent.find('.sistemazione-info-container').slideToggle();
          $sistemazione_parent.find('.sistemazione-item-expand').toggleClass('alter');
          $sistemazione_parent.find('.sistemazione-item-collapse').toggleClass('alter');
        });
      }
    }
  }

})(jQuery);

