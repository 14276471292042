/**
 * @file
 * Custom JS behaviour for Catalogue Cover.
 */

(function ($) {

  Drupal.behaviors.catalogue_cover = {
    attach: function (context, settings) {

      if ($('body.node-type-catalogo').length) {
        Cover();
      }

      function Cover() {

        var controller = new ScrollMagic.Controller({
          globalSceneOptions: {
            triggerHook: "onLeave"
          }
        });

        var tl = new TimelineMax();

        var $textContainerBackground = $(".text-container-background"),
            $image1 = $(".image1"),
            $image2 = $(".image2"),
            $image3 = $(".image3"),
            $catalogueTitle = $('h1'),
            $destinationsList = $('.view-display-id-block_2'),
            $cataloguePeriod = $('.catalogue-period'),
            $button = $('.cta'),
            $logo = $(".logo-cover"),
            $share = $(".share-links");

            TweenMax.set($image1, { opacity: 0 });
            TweenMax.set($image1, { opacity: 0, scale: 1.1 });
            TweenMax.set($image2, { opacity: 0, scale: 1, xPercent: 5 });
            TweenMax.set($image3, { opacity: 0, scale: 1, xPercent: 5 });
            TweenMax.set($catalogueTitle, { opacity: 0, x: -20 });
            TweenMax.set($destinationsList, { opacity: 0, x: -20 });
            TweenMax.set($cataloguePeriod, { opacity: 0 });
            TweenMax.set($button, { opacity: 0, x: -20 });
            TweenMax.set($logo, { opacity: 0, x: -20 });
            TweenMax.set($share, { opacity: 0 });

        new ScrollMagic.Scene({})
        .on("enter", function (event) {

          tl.to($textContainerBackground, 5, { opacity: 1, ease: Power4.easeOut })
            .to($image1, 5, { opacity: 1, scale: 1, xPercent: 0, ease: Power4.easeOut }, 0)
            .to($image2, 5, { opacity: 0.6, scale: 1, xPercent: 0, ease: Power4.easeOut }, "-=5")
            .to($image3, 5, { opacity: 1, scale: 1, xPercent: 0, ease: Power4.easeOut }, "-=5")
            .to($catalogueTitle, 1, { opacity: 1, x: 1, ease: Power4.easeOut }, "-=3.2")
            .to($destinationsList, 1, { opacity: 1, x: 0, ease: Power4.easeOut }, "-=3.0")
            .to($cataloguePeriod, 1, { opacity: 1, ease: Power4.easeOut }, "-=2.8")
            .to($button, 1, { opacity: 1, x: 0, ease: Power2.easeOut }, "-=3")
            .to($logo, 1, { opacity: 1, x: 0, ease: Power4.easeOut }, "-=3.2")
            .to($share, 1, { opacity: 1, ease: Power4.easeOut }, "-=2.5");
        })
        .on("leave", function (event) {
        })
        .addTo(controller);
      }


      if ($('#page-wrapper.cover.essentia-i7').length) {
        CoverEssentia();
      }

      function CoverEssentia() {

        var controller = new ScrollMagic.Controller({
          globalSceneOptions: {
            triggerHook: "onLeave"
          }
        });

        var tlEssentia = new TimelineMax();

        var $textContainerBackground = $(".text-container-background"),
            $image1 = $(".image1"),
            $catalogueTitle = $('h1'),
            $destinationsList = $('.view-display-id-block_2'),
            $cataloguePeriod = $('.catalogue-period'),
            $button = $('.cta'),
            $logo = $(".logo-cover");

            TweenMax.set($image1, { opacity: 0 });
            TweenMax.set($image1, { opacity: 0, scale: 1.1 });
            TweenMax.set($catalogueTitle, { opacity: 0, scale: 1.3 });
            TweenMax.set($destinationsList, { opacity: 0, scale: 1.1, yPercent: -5 });
            TweenMax.set($cataloguePeriod, { opacity: 0, scale: 1.1, yPercent: -5 });
            TweenMax.set($button, { opacity: 0, yPercent: -10 });
            TweenMax.set($logo, { opacity: 0, xPercent: -2 });

        new ScrollMagic.Scene({})
        .on("enter", function (event) {

          tlEssentia.to($textContainerBackground, 5, { opacity: 1, ease: Power4.easeOut })
            .to($image1, 5, { opacity: 1, scale: 1, ease: Power4.easeOut }, 0)
            .to($catalogueTitle, 1, { opacity: 1, scale: 1, ease: Power4.easeOut }, "-=3.2")
            .to($destinationsList, 1, { opacity: 1, scale: 1, yPercent: 0, ease: Power4.easeOut }, "-=3.0")
            .to($cataloguePeriod, 1, { opacity: 1, scale: 1, yPercent: 0, ease: Power4.easeOut }, "-=2.8")
            .to($button, 1, { opacity: 1, yPercent: 0, ease: Power4.easeOut }, "-=2.6")
            .to($logo, 1, { opacity: 1, xPercent: 0, ease: Power4.easeOut }, "-=3.2");
        })
        .on("leave", function (event) {
        })
        .addTo(controller);
      }


      if ($('#page-wrapper.cover.single-travellers-i7').length) {
        CoverSingle();
      }

      function CoverSingle() {

        var controller = new ScrollMagic.Controller({
          globalSceneOptions: {
            triggerHook: "onLeave"
          }
        });

        var tlSingle = new TimelineMax();

        var $textContainerBackground = $(".text-container-background"),
            $image1 = $(".image1"),
            $catalogueTitle = $('h1'),
            $destinationsList = $('.view-display-id-block_2'),
            $cataloguePeriod = $('.catalogue-period'),
            $button = $('.cta'),
            $logo = $(".logo-cover");

            TweenMax.set($image1, { opacity: 0 });
            TweenMax.set($image1, { opacity: 0, scale: 1.1 });
            TweenMax.set($catalogueTitle, { opacity: 0, scale: 1.3 });
            TweenMax.set($destinationsList, { opacity: 0, scale: 1.1, yPercent: -5 });
            TweenMax.set($cataloguePeriod, { opacity: 0, scale: 1.1, yPercent: -5 });
            TweenMax.set($button, { opacity: 0, yPercent: -10 });
            TweenMax.set($logo, { opacity: 0, xPercent: -2 });

        new ScrollMagic.Scene({})
        .on("enter", function (event) {

          tlSingle.to($textContainerBackground, 5, { opacity: 1, ease: Power4.easeOut })
            .to($image1, 5, { opacity: 1, scale: 1, ease: Power4.easeOut }, 0)
            .to($catalogueTitle, 1, { opacity: 1, scale: 1, ease: Power4.easeOut }, "-=3.2")
            .to($destinationsList, 1, { opacity: 1, scale: 1, yPercent: 0, ease: Power4.easeOut }, "-=3.0")
            .to($cataloguePeriod, 1, { opacity: 1, scale: 1, yPercent: 0, ease: Power4.easeOut }, "-=2.8")
            .to($button, 1, { opacity: 1, yPercent: 0, ease: Power4.easeOut }, "-=2.6")
            .to($logo, 1, { opacity: 1, xPercent: 0, ease: Power4.easeOut }, "-=3.2");
        })
        .on("leave", function (event) {
        })
        .addTo(controller);
      }
    }
  }

})(jQuery);

