/**
 * @file
 * Custom JS behaviour for Menu.
 */

(function ($) {
  Drupal.behaviors.main_menu = {
    attach: function (context, settings) {

      var window_width = document.documentElement.clientWidth;
      var window_height = document.documentElement.clientHeight;

      var $body = $('body');
      var $header = $('#header');
      var $hamburger = $('.hamburger', context);
      var $mainMenu = $('.main-menu', context);
      var $menu_top = $('.menu-on-top', context);
      var belowMenu_pxWidth = window_width < small ? window_width + 'px' : '250px';
      var headerHeight = $header.height();

      if ($mainMenu.length) {

        var mainMenuCss = {
          'opacity': 1
        };

        $mainMenu.css(mainMenuCss);

        var $mainMenuRows = $mainMenu.find('.main-menu-row');
        var $mainMenuItems = $mainMenu.find('.main-menu-item');
        var $belowMenuContainer = $mainMenu.find('.below-menu-container');
        var $belowMenus = $belowMenuContainer.find('.below-menu');
        var $belowMenuRows = $belowMenuContainer.find('.below-menu-type-container');
        var $overlayMenu = $('.menu-layer');
        var timescale_reverse = 1.5;

        $mainMenu.find(' > .view-content').height(window_height - headerHeight);
        $belowMenuContainer.height(window_height - headerHeight);
        $belowMenus.height(window_height - headerHeight);

        var menuTimeLine = new TimelineMax({
          paused: true,
          onStart: function () {
            if (menuTimeLine.reversed() === true) {
              $mainMenu.removeClass('opened');
            }
          },
          onUpdate: function () {
            if (menuTimeLine.progress() >= 0.5 && menuTimeLine.reversed() === false) {
              $mainMenu.addClass('opened');
            }
          },
          onComplete: function () {
            // We update the scrollbar as container changed.
            $mainMenu.find(' > .view-content').perfectScrollbar('update');
          }
        });

        var belowMenuTimeLine = new TimelineMax({
          paused: true
        });

        var belowBelowMenuTimeLine = new TimelineMax({
          paused: true
        });

        // We try to calculate an optimized height for fitting window height
        // If possible.
        var row_height = (window_height - headerHeight) / $mainMenuRows.length;

        /* $mainMenuRows.css({
          'height': row_height + 'px'
        });
        $mainMenuRows.find('.field-name-field-immagini').css({
          'height': row_height + 'px'
        }); */

        TweenMax.set($mainMenu, {
          xPercent: -100
        });
        TweenMax.set($mainMenuRows, {
          xPercent: -100
        });
        /* TweenMax.set($belowMenuRows, {
          xPercent: -100
        }); */

        menuTimeLine.to($mainMenu, 0.1, {
          xPercent: 0
        }, .5)
          .staggerTo($mainMenuRows, 0.5, {
            xPercent: 0,
            ease: Power4.easeOut,
          }, 0.1);

        // Click on Hamburger.
        $hamburger.off('click').on('click', function (e) {

          // We deselect all menu items.
          $mainMenuRows.removeClass('selected');
          $(this).toggleClass('is-active');
          $header.toggleClass('menu-opened');
          $overlayMenu.toggleClass('active');

          // We play the timeline.
          if ($(this).hasClass('is-active')) {
            $body.addClass('menu-opened');
            menuTimeLine.timeScale(1);
            menuTimeLine.play();
          }
          // We close the menu by reversing the timeline.
          else {
            if (window_width < small) {
              $mainMenuItems.removeClass('reduced');
              $mainMenuRows.removeClass('reduced');
              $mainMenuRows.removeClass('selected');
              $('.below-menu-container.selected').removeClass('selected');
              $('.below-menu-type-rows', context).not('.opened').slideUp();
              resetBelowMenu();
              timeLineReverse(belowBelowMenuTimeLine);
              timeLineReverse(belowMenuTimeLine);
              timeLineReverse(menuTimeLine);
              $body.removeClass('menu-opened');
            }
            else if (!belowBelowMenuTimeLine.reversed()) {
              belowBelowMenuTimeLine.eventCallback("onReverseComplete", function () {
                resetBelowMenu();
                $('.below-menu-container.selected').removeClass('selected');
                belowMenuTimeLine.eventCallback("onReverseComplete", function () {
                  $mainMenuItems.removeClass('reduced');
                  $mainMenuRows.removeClass('reduced');
                  $mainMenuRows.removeClass('selected');
                  timeLineReverse(menuTimeLine);
                  $body.removeClass('menu-opened');
                });
                timeLineReverse(belowMenuTimeLine);
              });
              timeLineReverse(belowBelowMenuTimeLine);
            }
            else if (!belowMenuTimeLine.reversed()) {
              belowMenuTimeLine.eventCallback("onReverseComplete", function () {
                $mainMenuItems.removeClass('reduced');
                $mainMenuRows.removeClass('reduced');
                $mainMenuRows.removeClass('selected');
                $('.below-menu-container.selected').removeClass('selected');
                resetBelowMenu();
                menuTimeLine.timeScale(timescale_reverse);
                menuTimeLine.reverse();
                $body.removeClass('menu-opened');
              });
              timeLineReverse(belowMenuTimeLine);
            }
            else {
              $mainMenuItems.removeClass('reduced');
              $mainMenuRows.removeClass('reduced');
              $mainMenuRows.removeClass('selected');
              $('.below-menu-type-rows', context).not('.opened').slideUp();
              resetBelowMenu();
              timeLineReverse(menuTimeLine);
              $body.removeClass('menu-opened');
            }
          }
        });

        // Click on menu items.
        $mainMenuRows.click(function (e) {
          e.preventDefault();

          var $current_menuitem = $(this);
          var nid = $current_menuitem.data('nid');
          var level = $current_menuitem.data('level');
          var below = $current_menuitem.data('below');

          if ($('div[data-section="' + below + '"]').length === 0) {
            $.ajax({
              url: settings.baseUrl + "/catalogue-menu-ajax/" + nid + "/" + level,
              context: this
            }).done(function (data) {
              $mainMenu.find('> .view-content').append(data);
              activateMenuItem($current_menuitem);
            });
          } else {
            activateMenuItem($current_menuitem);
          }

        });

        $(document).on('click', '.below-node-row', function (e) {

          e.preventDefault();

          var $current_menuitem = $(this);
          var nid = $current_menuitem.data('nid');
          var level = $current_menuitem.data('level');
          var below = $current_menuitem.data('below');

          if ($('div[data-section="' + below + '"]').length === 0) {
            $.ajax({
              url: settings.baseUrl + "/catalogue-menu-ajax/" + nid + "/" + level,
              context: this,
              beforeSend: function (xhr) {
                $current_menuitem.find('.menu-loader-wrapper').addClass('show');
              }
            }).done(function (data) {
              $current_menuitem.find('.menu-loader-wrapper').removeClass('show');
              $mainMenu.find('> .view-content').append(data);
              activateBelowMenuItem($current_menuitem);
            });
          } else {
            activateBelowMenuItem($current_menuitem);
          }

        });

        function resetMainMenu() {
          $mainMenuItems.removeClass('reduced');
          $mainMenuRows.removeClass('reduced');
          $mainMenuRows.removeClass('selected');
        }

        function resetBelowMenu() {
          $('.below-menu-type-rows', context).not('.opened').slideUp();
          $('.below-menu-type-label', context).removeClass('selected');
          $('.below-node-row', context).removeClass('selected inactive');
        }

        function timeLineReverse(timeLine) {
          timeLine.timeScale(timescale_reverse);
          timeLine.reverse();

          return timeLine;
        }

        function belowTimelineAnimationReset($currentBelowMenu, $currentBelowMenuRows, callback_complete) {
          var timeLine = new TimelineMax({
            paused: true,
            onComplete: callback_complete
          });

          timeLine.to($currentBelowMenu, 0.25, {
            css: {
              width: belowMenu_pxWidth
            }
          })
            .staggerFromTo($currentBelowMenuRows, 0.5, {
              xPercent: -100,
              ease: Power4.easeOut,
            }, {
              xPercent: 0,
              ease: Power4.easeOut,
            }, 0.1);

          return timeLine;
        }

        function activateMenuItem($current_menuitem) {
          // If we clicked on current selected
          // We deselect and reverse the below menu timeline.
          if ($current_menuitem.hasClass('selected')) {
            belowMenuTimeLine.eventCallback("onReverseComplete", function () {
              resetMainMenu()
              resetBelowMenu();
            });

            if (belowBelowMenuTimeLine.time() > 0) {
              belowBelowMenuTimeLine.eventCallback("onReverseComplete", function () {
                resetBelowMenu();
                timeLineReverse(belowMenuTimeLine);
              });

              timeLineReverse(belowBelowMenuTimeLine);
            }
            else {
              timeLineReverse(belowMenuTimeLine);
            }

            return;
          }

          $mainMenuRows.removeClass('selected');
          $current_menuitem.addClass('selected');

          var $currentBelowMenu = $('div[data-section="' + $current_menuitem.data('below') + '"]');
          var $currentBelowMenuRows = $currentBelowMenu.find('.below-menu-type-container').length ? $currentBelowMenu.find('.below-menu-type-container') : $currentBelowMenu.find('.below-node-row');

          $('.below-menu-container.selected', context).removeClass('selected');
          $currentBelowMenu.closest('.below-menu-container').addClass('selected');

          // Activate scrollbar on belowmenu.
          $currentBelowMenu.perfectScrollbar('update');

          // We reduce the main menu items.
          if (!$mainMenuItems.hasClass('reduced')) {
            $mainMenuItems.addClass('reduced');
            $mainMenuRows.addClass('reduced');
          }

          // If the below timeline has been launched.
          // We reverse it.
          if (belowMenuTimeLine.time() > 0) {
            // If third level timeline has been launched.
            // We reverse it.
            if (belowBelowMenuTimeLine.time() > 0) {
              belowBelowMenuTimeLine.eventCallback("onReverseComplete", function () {
                belowMenuTimeLine.eventCallback("onReverseComplete", function () {
                  resetBelowMenu();
                  belowMenuTimeLine = belowTimelineAnimationReset($currentBelowMenu, $currentBelowMenuRows, null);
                  belowMenuTimeLine.play();
                });

                timeLineReverse(belowMenuTimeLine);
              });

              timeLineReverse(belowBelowMenuTimeLine);
            }
            else {
              belowMenuTimeLine.eventCallback("onReverseComplete", function () {
                resetBelowMenu();
                belowMenuTimeLine = belowTimelineAnimationReset($currentBelowMenu, $currentBelowMenuRows, null);
                belowMenuTimeLine.play();
              });

              timeLineReverse(belowMenuTimeLine);
            }
          }
          // Or else we set the belowtimeline
          // And play it.
          else {

            belowMenuTimeLine = belowTimelineAnimationReset($currentBelowMenu, $currentBelowMenuRows, function () {
              if (window_width < small) {
                $currentBelowMenu.find('.below-menu-mobile-close').click(function (e) {
                  $('.below-menu-container.selected', context).removeClass('selected');
                  activateMenuItem($current_menuitem);
                });
              }
            });

            belowMenuTimeLine.play();
          }
        }

        function activateBelowMenuItem($current_menuitem) {
          // If we clicked on current selected
          // We deselect and reverse the below menu timeline.

          if ($current_menuitem.hasClass('selected')) {
            belowBelowMenuTimeLine.eventCallback("onReverseComplete", function () {
              resetBelowMenu();
            });

            timeLineReverse(belowBelowMenuTimeLine);
            return;
          }

          $('.below-node-row.selected', context).removeClass('selected');
          $('.below-node-row', context).not($current_menuitem[0]).addClass('inactive');
          $current_menuitem.addClass('selected');

          var $currentBelowMenu = $('div[data-section="' + $current_menuitem.data('below') + '"]');
          var $currentBelowMenuRows = $currentBelowMenu.find('.below-menu-type-container');

          $('.below-menu-container.level-2.selected', context).removeClass('selected');
          $currentBelowMenu.closest('.below-menu-container').addClass('selected');

          // Activate scrollbar on belowmenu.
          // $currentBelowMenu.perfectScrollbar('update');
          // Activate scrollbar on belowmenu.
          if ($currentBelowMenu.hasClass('ps') === false) {
            $currentBelowMenu.perfectScrollbar({
              suppressScrollX: true
            });
          }
          else {
            $currentBelowMenu.perfectScrollbar('update');
          }

          // If the below timeline has been launched.
          // We reverse it.
          if (belowBelowMenuTimeLine.time() > 0) {

            belowBelowMenuTimeLine.eventCallback("onReverseComplete", function () {
              resetBelowMenu();
              // belowBelowMenuTimeLine = belowTimelineAnimationReset($currentBelowMenu, $currentBelowMenuRows, null);
              activateBelowMenuItem($current_menuitem);
            });

            timeLineReverse(belowBelowMenuTimeLine);
          }
          // Or else we set the belowtimeline.
          // And play it.
          else {
            belowBelowMenuTimeLine = belowTimelineAnimationReset($currentBelowMenu, $currentBelowMenuRows, function () {
              if (window_width < small) {
                $currentBelowMenu.find('.below-menu-mobile-close').click(function (e) {
                  $('.below-menu-container.level-2.selected', context).removeClass('selected');
                  activateBelowMenuItem($current_menuitem);
                });
              }
            });

            belowBelowMenuTimeLine.play();
          }
        }

        $(document).on('click', '.below-menu-type-label', function (e) {

          e.preventDefault();

          var $this = $(this);
          var $below_labels = $this.next('.below-menu-type-rows');

          $('.below-menu-type-label.selected', context).not(this).removeClass('selected');
          $('.below-menu-type-rows', context).not($below_labels[0]).not('.opened').slideUp();

          $(this).toggleClass('selected');
          var next_rows = $below_labels.find('.below-menu-type-row');
          var slide_time = (next_rows.length * 150) > 500 ? 500 : next_rows.length * 150;
          $below_labels.slideToggle(slide_time);
          setTimeout(function () {
            // Activate scrollbar on belowmenu.
            $('.below-menu', context).perfectScrollbar('update');
          }, slide_time + 100);
        });

        // Click on back to menu in header fixed
        // We trigger the menu at scroll top animation end.
        $menu_top.off('click').on('click', function (e) {
          e.preventDefault();

          TweenLite.to(window, 1, {
            scrollTo: {
              y: 0,
              autoKill: false
            },
            onComplete: function () {
              if (!$mainMenu.hasClass('opened')) {
                $hamburger.trigger('click');
              }
            }
          });
        });

        // Custom scrollbar on menu.
        $mainMenu.find(' > .view-content').perfectScrollbar({
          suppressScrollX: true
        });

        // Activate scrollbar on belowmenu.
        $belowMenus.perfectScrollbar({
          suppressScrollX: true
        });
      }

    }
  }
})(jQuery);
